<template>
  <div>
    <v-row class="justify-end align-center mx-4 mb-4">
      <v-text-field
        v-model="search"
        class="flex-grow-0"
        :label="$t('common.search')"
        prepend-inner-icon="search"
        tag="span"
        outlined
        dense
        hide-details
      />
    </v-row>
    <v-data-table :headers="headers" :items="viewers" :search="search" dense />
  </div>
</template>

<script>
import i18nCountries from '@/utils/mixins/i18nCountries'

export default {
  name: 'StreamingStatsViewerList',
  mixins: [i18nCountries],
  props: {
    streaming: { type: Object, required: true },
  },
  data() {
    return {
      search: '',
      viewers: [],
    }
  },
  computed: {
    headers() {
      return [
        { text: this.$t('user.fields.name'), value: 'name' },
        { text: this.$t('user.fields.email'), value: 'email' },
        { text: this.$t('user.fields.country'), value: 'country' },
        { text: this.$t('user.fields.birthdate'), value: 'birthdate', filterable: false },
      ]
    },
    viewerIds() { return this.streaming.stats.viewers },
  },
  watch: {
    viewerIds: {
      immediate: true,
      async handler(viewerIds) {
        this.$store.commit('loader/show')
        this.viewers = await Promise.all(viewerIds.map(async userId => {
          const user = await this.$store.dispatch('user/read', userId)
          return {
            name: `${user.firstName} ${user.lastName}`,
            email: user.email,
            country: user.country ? this.countries[user.country] : '-',
            birthdate: `${user.birthdate.year}-${user.birthdate.month}-${user.birthdate.day}`,
          }
        }))
        this.$store.commit('loader/hide')
      },
    },
  },
}
</script>
